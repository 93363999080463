<div class="main-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="title-wrapper" cdkDragHandle>
    <span class="mat-headline-6 ellipsis" [matTooltip]="title" [matTooltipDisabled]="!showTitleTooltip">{{ title }}</span>
    <div class="projected-content">
      <ng-content></ng-content>
    </div>
  </div>

  @if (!hideCloseButton) {
    <button mat-icon-button (click)="closeDialog()" [matTooltip]="'TOOLTIP.CLOSE' | transloco">
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
